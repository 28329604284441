import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import CourseForm from '../courses/CourseForm';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

function BatchForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { response, loading, reFetch } = useAxiosGet(endpoints.course.get);

    const courses = response.filter(
        (course) => Number(course.COURSE_STATUS) === 1
    );

    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const formatBatchDate = (updateValues, dateKey) => {
        if (updateValues && updateValues[dateKey]) {
            let batchDate = new Date(updateValues[dateKey]);
            let formattedDate = batchDate.toISOString().split('T')[0];
            updateValues[dateKey] = formattedDate;
        }
    };

    formatBatchDate(updateValues, 'BATCH_END_DATE');

    formatBatchDate(updateValues, 'BATCH_START_DATE');

    const initialFormValues = updateValues || {
        BATCH_NAME: '',
        // BATCH_CODE: '',
        COURSE_ID: '',
        BATCH_START_DATE: '',
        BATCH_END_DATE: '',
    };

    if (loading) {
        return <Loader />;
    }
    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.course = courses.find(
            (course) =>
                Number(course.COURSE_ID) === Number(initialFormValues.COURSE_ID)
        );
    }

    return (
        <Formik
            initialValues={initialFormValues}
            // Designation: Yup.string().when('IsEmployed', {
            //     is: '1',
            //     then: () => Yup.string().required('Required'),
            //     otherwise: () => Yup.string().nullable(),
            // }),
            validationSchema={Yup.object({
                BATCH_NAME: Yup.string().required(),
                COURSE_ID: Yup.string().required(),
                BATCH_START_DATE: Yup.date().required(),
                BATCH_END_DATE: Yup.date().when(
                    'BATCH_START_DATE',
                    (BATCH_START_DATE, schema) => {
                        return BATCH_START_DATE
                            ? schema.min(
                                BATCH_START_DATE,
                                'The end date cannot be less than start date'
                            )
                            : schema.required('Required');
                    }
                ),
                // BATCH_CODE: Yup.number().min(1).required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    {/* {!updateValues && (
                        <FormikControl
                            control='input'
                            required
                            label='Batch Code'
                            name='BATCH_CODE'
                        />
                    )} */}
                    <FormikControl
                        control='input'
                        required
                        label='Batch Name'
                        name='BATCH_NAME'
                    />
                    <FormikControl
                        control='input'
                        type='date'
                        required
                        name='BATCH_START_DATE'
                        label='Batch start Date'
                    />
                    <FormikControl
                        control='input'
                        type='date'
                        required
                        name='BATCH_END_DATE'
                        label='Batch End Date'
                    />
                    <FormikControl
                        control='select-add'
                        Form={CourseForm}
                        formProps={{
                            formSize: 'sm',
                            title: 'Course',
                            endpoint: endpoints.course.add,
                            onAfterSubmit: reFetch,
                        }}
                        defaultValue={preSelectedOption.course}
                        options={courses}
                        optionValue='COURSE_ID'
                        optionLabel='COURSE_NAME'
                        label='COURSE'
                        required
                        name='COURSE_ID'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(BatchForm);
