import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import PersonalTagForm from './PersonalTagForm';
import DeleteWithWarningTags from '../../components/DeleteWithWarningTags';
import withAlert from '../../components/withAlert';

function PersonalTags({ showAlert }) {
    const fetchUtils = useAxiosGet(endpoints.personalTags.get);

    const cellModifier = {
        Delete: ({ row }) => (
            <div className='td text-center'>
                <DeleteWithWarningTags
                    title={'Personal Tags'}
                    configBody={{
                        ...row.original,
                    }}
                    className='mx-auto'
                    onAfterDelete={() => {
                        fetchUtils.reFetch();
                        showAlert(
                            'success',
                            'The tag has been removed successfully'
                        );
                    }}
                    endpoint={endpoints.personalTags.delete}
                />
            </div>
        ),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.personalTags}
                {...viewProps.PersonalTags}
                relevants={[
                    ...(viewProps.PersonalTags.relevants || []),
                    ...['Delete'],
                ]}
                columnHeads={[
                    ...(viewProps.PersonalTags.columnHeads || []),
                    ...['Delete'],
                ]}
                Form={PersonalTagForm}
                {...fetchUtils}
                irremovable
                cellModifier={cellModifier}
            />
        </>
    );
}

export default withAlert(PersonalTags);
