import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import AlumniJobDomain from './AlumniJobDomain';
import Chip from '../dashboard/Chip';
import ECMembers from '../dashboard/ECMembers';
import EntreInd from '../dashboard/EntreInd';
import AlumniGraduated from '../dashboard/AlumniGraduated';
import CityChapterInclusion from '../dashboard/CityChapterInclusion';
import Loader from '../../components/Loader';
import Entrepreneurs from './EntrepreneurBloodDonor';
import MarkAsSafe from './MarkAsSafe';
import EventAnnouncement from './EventAnnouncement';
import HelpJobRequest from './HelpJobRequest';
import CityChapter from './CityChapter';
import UserGroup from './UserGroup';
import { ReactComponent as CityChapterIcon } from '../../asset/icons/CityChapterIcon.svg';

function Dashboard() {
    const { response, loading } = useAxiosGetMultiple(
        {
            basics: endpoints.statistics.basics,
            alumniJobIndustry: endpoints.statistics.alumniJobIndustry,
            alumniJobOrg: endpoints.statistics.alumniJobOrg,
            alumniPerYear: endpoints.statistics.alumniPerYear,
            cchAlumniCount: endpoints.statistics.cchAlumniCount,
            ecMembers: endpoints.statistics.entreprener,
            unsafeResponse: endpoints.instituteMember.unsafeResponse,
            totalHelpRequests: endpoints.instituteMember.totalHelpRequests,
            totalJobRequests: endpoints.instituteMember.totalJobRequests,
            registeredStudents: endpoints.instituteMember.registeredStudents,
            inactiveStudents: endpoints.instituteMember.inactiveStudents,
            totalAlumni: endpoints.instituteMember.totalAlumni,
            inactiveAlumni: endpoints.instituteMember.inactiveAlumni,
            facultyCount: endpoints.instituteMember.facultyCount,
            inactiveFaculty: endpoints.instituteMember.inactiveFaculty,
        }
        // { batch: false }
    );

    const {
        basics,
        ecMembers,
        // cityChapterInclusion,
        alumniJobIndustry,
        alumniJobOrg,
        alumniPerYear,
        entreByIndustry,
        cchAlumniCount,
        unsafeResponse,
        totalHelpRequests,
        totalJobRequests,
        registeredStudents,
        inactiveStudents,
        totalAlumni,
        inactiveAlumni,
        facultyCount,
        inactiveFaculty,
    } = response;
    // console.log(, 'unsafe');
    // const cityChapterInclusion = response.cityChapterInclusion.filter(
    //     (data) => !!data.COUNT_ACTIVE_ALUMNI
    // );
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Institution Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FF9A9A' title='Unsafe Response 🚨'>
                                {unsafeResponse?.unsafeResponse ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Total Help Requests 🚨'>
                                {totalHelpRequests?.totalHelpRequests ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#C6FAD8' title='Total Job Requests'>
                                {totalJobRequests?.totalJobreqCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Registered Students '>
                                {registeredStudents?.totalStudentCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Inactive Students'>
                                {inactiveStudents?.totalInactiveAlumniCount ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Total Alumni'>
                                {totalAlumni?.totalAlumniCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Inactive Alumni'>
                                {inactiveAlumni?.totalInactiveAlumniCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Faculty Count'>
                                {facultyCount?.totalFacultyCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Inactive Faculty'>
                                {inactiveFaculty?.totalInactiveFacultyCount ??
                                    0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <UserGroup />
                </Col>
                <Col md='14' className='gy-5'>
                    <MarkAsSafe
                        data={{
                            industry: alumniJobIndustry,
                            organization: alumniJobOrg,
                        }}
                    />
                </Col>
                <Col md='14' className='gy-5'>
                    <Col className='d-flex'>
                        <CityChapterIcon />
                        <Col className='px-2'>
                            <div className='small h6 text-dark'>
                                Number of Alumni Graduated
                            </div>

                            <div
                                className='text-muted'
                                style={{ fontSize: '.75em' }}
                            >
                                Yearly Basis
                            </div>
                        </Col>
                    </Col>
                    <AlumniGraduated data={alumniPerYear} />
                </Col>

                <Col md='14' className='gy-5'>
                    <Col className='d-flex'>
                        <CityChapterIcon />
                        <Col className='px-2'>
                            <div className='small h6 text-dark'>
                                Inclusion of Alumni in City Chapter
                            </div>

                            <div
                                className='text-muted'
                                style={{ fontSize: '.75em' }}
                            >
                                Based on City Chapter
                            </div>
                        </Col>
                    </Col>
                    <Col md='14' className='gy-5 d-flex justify-content-center'>
                        <CityChapter cchAlumniCount={cchAlumniCount} />
                    </Col>
                </Col>
                <Row>
                    <Col md='4' className='gy-5 w-50'>
                        <AlumniJobDomain
                            data={{
                                industry: alumniJobIndustry,
                                organization: alumniJobOrg,
                            }}
                        />
                    </Col>
                    <Col md='4' className='gy-5 w-50'>
                        <Entrepreneurs entreprenerList={ecMembers} />
                    </Col>
                </Row>

                <Col md='14' className='gy-5'>
                    <EventAnnouncement
                        data={{
                            industry: alumniJobIndustry,
                            organization: alumniJobOrg,
                        }}
                    />
                </Col>
                <Col md='14' className='gy-5'>
                    <HelpJobRequest
                        data={{
                            industry: alumniJobIndustry,
                            organization: alumniJobOrg,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
