import React from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import Avatar from '../../asset/img/Avatar.png';

function BloodDonorTable({ selectedFilter }) {
    const getApi = endpoints.statistics.bloodDonor;
    const fetchUtils = useAxiosGet(getApi);

    const filteredResponse = fetchUtils?.response?.alumniDetails?.filter(
        (item) => {
            switch (selectedFilter) {
                case 'Student':
                    return item.userType === 1;
                case 'Alumni':
                    return item.userType === 2;
                case 'Faculty':
                    return item.userType === 3;
                case 'Entrepreneur':
                    return item.IsEntrepreneur === 1;
                default:
                    return true;
            }
        }
    );

    return (
        <table
            className='table'
            style={{
                borderRadius: '12px',
                overflow: 'hidden',
                width: '100%',
            }}
        >
            <thead style={{ borderRadius: '12px' }}>
                <tr style={{ borderRadius: '12px' }}>
                    <th>Name</th>
                    <th>Blood Group</th>
                </tr>
            </thead>
            <tbody style={{ overflowY: 'scroll' }}>
                {filteredResponse?.map((person, index) => (
                    <tr key={index} style={{ borderRadius: '12px' }}>
                        <td
                            style={{
                                width: '50%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    style={{
                                        width: '10%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        src={person.ALUMNI_IMAGE_URL || Avatar}
                                        alt='Avatar'
                                        className='rounded-circle shadow-sm'
                                        style={{
                                            border: '3px solid white',
                                            width: '100%',
                                        }}
                                        width='30'
                                    />
                                </div>
                                <div
                                    style={{
                                        fontSize: '14px',
                                        paddingLeft: '2%',
                                        width: '50%',
                                    }}
                                >
                                    {person.ALUMNI_Name}
                                    <br />
                                    {person.BATCH_NAME}
                                    {person.BATCH_CODE}
                                </div>
                            </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            {person.bloodGroups}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default BloodDonorTable;
