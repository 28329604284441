const today = new Date().getFullYear();

const errorObjects = [
    {
        errorType: 'allErrorResponseData',
        errorArea: 'Row:',
        errorNum: 'row',
        errorColumn: 'column',
        josonErrorMsg: 'message',
    },
    {
        errorType: 'errorColumnValues',
        errorArea: 'Row:',
        errorNum: 'row',
        errorColumn: 'column',
        josonErrorMsg: 'message',
    },
    {
        errorType: 'repeatEmail',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'USER_EMAIL',
        errorMsg: 'Email repeated in template: ',
    },
    {
        errorType: 'invalidEmail',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'USER_EMAIL',
        errorMsg: 'Email already in use',
    },
    {
        errorType: 'inValidFacultyItems',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'Batch & Course details',
        errorMsg: 'Batch & Course details should not be null',
    },
    {
        errorType: 'invalidBatchInstitution',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'BATCH NAME',
        errorMsg:
            'The batch code is used in different institution,kindly change it',
    },
    {
        errorType: 'batchNameMismatch',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'BATCH CODE & BATCH NAME',
        errorMsg: 'The batch code and name does not match',
    },
    {
        errorType: 'batchCourseMismatch',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'BATCH CODE & COURSE',
        errorMsg: 'The batch and course does not match',
    },
    {
        errorType: 'invalidDate',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'BATCH START DATE & BATCH END DATE',
        errorMsg:
            'The batch start date and batch end date should follow yyyy-mm-dd',
    },
    {
        errorType: 'invalidOrganization',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'ORGANIZATION NAME',
        errorMsg: 'Organization name is required and should be text',
    },
    {
        errorType: 'invalidIndustry',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'INDUSTRY NAME',
        errorMsg: 'Industry name is required and should be text',
    },
    {
        errorType: 'invalidDesignation',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'USER DESIGNATION',
        errorMsg: 'Designation name is required and should be text',
    },
    {
        errorType: 'invalidStartDate',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'Batch Start Date',
        errorMsg:
            'The batch start date must be earlier than or equal to the batch end date.',
    },
    {
        errorType: 'emptyGraduation',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'YEAR_OF_GRADUATION',
        errorMsg: 'The year of graduation is mandatory for alumni and student.',
    },
    {
        errorType: 'emptyLinkedin',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'LINKEDIN PROFILE LINK',
        errorMsg: 'The LinkedIn profile link is required.',
    },
    {
        errorType: 'invalidGraduationAlumni',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'YEAR OF GRADUATION',
        errorMsg: `The year of graduation for alumni should be in yyyy format and should be between ${
            today - 100
        } & ${today}`,
    },
    {
        errorType: 'invalidGraduationStudent',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'YEAR OF GRADUATION',
        errorMsg: `The year of graduation for a student should be in yyyy format and must be equal to or greater than ${today}`,
    },
    {
        errorType: 'invalidDob',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'DATE_OF_BIRTH',
        errorMsg: 'Invalid value for date of birth field: ',
    },
    {
        errorType: 'emptyBloodGroup',
        errorArea: 'Row:',
        errorNum: 'row',
        errorField: 'BLOOD GROUP',
        errorMsg: 'The Blood Group is required.',
    },
];

export default errorObjects;
