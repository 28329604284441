import React, { useState } from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import AlumniForm from './AlumniForm';
import viewProps from '../viewProps';
import BulkUpload from '../../components/BulkUpload';
import { useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import errorObjects from './alumniErrorObjects';
import DownloadExcelWithWantedColumns from '../../components/DownloadExcelWithWantedColumns';
import objCloneKeyValue from '../../helpers/objCloneKeyValue';

function AlumniDetails() {
    const { user } = useAuthenticationState();
    const [isStudent, setIsStudent] = useState(
        [
            'STUDENTCOUNCIL',
            'CLASSREPRESENTATIVE',
            'CLASSREP + STUDENT COUNCIL',
        ].includes(user.Role)
    );

    let getApi = endpoints.alumniDetail.getAlumniOnly;

    if (
        [
            'DELEGATE',
            // 'DELEGATE + CCH',
            // 'STUDENTCOUNCIL',
            // 'CLASSREPRESENTATIVE',
            // 'CLASSREP + STUDENT COUNCIL',
        ].includes(user.Role)
    ) {
        getApi = endpoints.alumniDetail.getBatchWise;
    }

    const fetchUtils = useAxiosGet(getApi);

    const { state } = useLocation();

    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const options = { month: 'long', day: 'numeric' };
    const cellModifier = {
        // DateOfBirth: ({ value }) => new Date(value).toDateString(),

        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        isBloodDonor: ({ value }) => {
            const status = {
                0: 'NO',
                1: 'YES',
            };
            return status[value];
        },
        BATCH_CODE: ({ value }) => {
            return value.map((report) => report.BATCH_NAME).join(', ');
        },
        courses: ({ row }) => {
            return row.original.BATCH_CODE.map(
                (report) => report.COURSE_NAME
            ).join(', ');
        },
        user_type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
        bloodGroup: ({ value }) => {
            const BLOOD_GROUPS = {
                1: 'A +ve',
                2: 'A -ve',
                3: 'B +ve',
                4: 'B -ve',
                5: 'AB +ve',
                6: 'AB -ve',
                7: 'O +ve',
                8: 'O -ve'
            };
            return BLOOD_GROUPS[value] || 'Not Disclosed';
        },
    };

    const alumniData = objCloneKeyValue(
        fetchUtils.response,
        'user_Type',
        'user_type'
    );

    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniDetail}
                Form={AlumniForm}
                formSize='lg'
                cellModifier={cellModifier}
                {...fetchUtils}
                {...viewProps.AlumniDetails}
                headerExtras={
                    <>
                        {!isStudent && (
                            <BulkUpload
                                urlObjects={{
                                    templateUrl:
                                        endpoints.alumniDetail
                                            .bulkUploadTemplate,
                                    fileUpload:
                                        endpoints.alumniBulkUpload.fileUpload,
                                    validateFile:
                                        endpoints.alumniBulkUpload.validateFile,
                                    initialBulkInsert:
                                        endpoints.alumniBulkUpload
                                            .initialBulkInsert,
                                    bulkUploadConfirm:
                                        endpoints.alumniBulkUpload
                                            .bulkUploadConfirm,
                                }}
                                filename='Alumni Details'
                                afterUpload={fetchUtils.reFetch}
                                templateExtension='.xlsx'
                                errorObjects={errorObjects}
                            />
                        )}
                        <DownloadExcelWithWantedColumns
                            downloadFileName={'Alumni Details'}
                            relevants={viewProps.AlumniDetails.relevants}
                            // unwantedRelevant={['Delete']}
                            columnHeads={viewProps.AlumniDetails.columnHeads}
                            response={fetchUtils.response}
                            keyInArrayTypeColumn='BATCH_NAME'
                            isAlumniTable
                        />
                    </>
                }
                initialFilterValue={state || ''}
                irremovable
                immutable={isStudent}
                insertable={!isStudent}
                errorObjects={errorObjects}
                downloadable={false}
                response={alumniData}
            />
        </>
    );
}

export default AlumniDetails;
