import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import './Benefits.css'

function BenefitDetails() {
    const { response, ...fetchUtils } = useAxiosGetMultiple({
        life: endpoints.lifeInsuranceDetails.get,
        health: endpoints.healthInsuranceDetails.get,
        // card: '/web/cardandsubscriptions/carddetails',
        // subscriptions: '/web/cardandsubscriptions/subscriptiondetails',
    });

    const { life, health, card, subscriptions } = response;
    // console.log(subscriptions);
    const cellModifier = {
        Alumni_LifeInsurance_STATUS: ({ value }) => {
            if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
        Alumni_MedicalInsurance_STATUS: ({ value }) => {
            if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
    };

    return (
        <div className='custom-tab'>
            <Tabs fill className='mb-4 life-tab' mountOnEnter >
                <Tab eventKey='life' title='Insurance - Life'>
                    <CRUDTable
                        {...viewProps.InsuranceDetailsLife}
                        cellModifier={cellModifier}
                        // irremovable
                        insertable={false}
                        immutable
                        {...fetchUtils}
                        response={life}
                        endpoints={endpoints.lifeInsuranceDetails}
                    />
                </Tab>
                <Tab eventKey='health' title='Insurance - Health'>
                    <CRUDTable
                        {...viewProps.InsuranceDetailsHealth}
                        cellModifier={cellModifier}
                        // irremovable
                        insertable={false}
                        immutable
                        {...fetchUtils}
                        response={health}
                        endpoints={endpoints.healthInsuranceDetails}
                    />
                </Tab>
                {/* <Tab eventKey='card' title='Card'>
                    <CRUDTable
                        {...viewProps.BenefitDetailsCard}
                        irremovable
                        insertable={false}
                        immutable
                        {...fetchUtils}
                        response={card}
                    />
                </Tab>
                <Tab eventKey='subscription' title='Subscription'>
                    <CRUDTable
                        {...viewProps.BenefitDetailsSubscription}
                        irremovable
                        insertable={false}
                        immutable
                        {...fetchUtils}
                        response={subscriptions}
                    />
                </Tab> */}
            </Tabs>
        </div>
    );
}

export default BenefitDetails;
