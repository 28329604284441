import React, { useState } from 'react';
import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';
import BloodDonorTable from './BloodDonorTable';
import { ReactComponent as Safe } from '../../asset/icons/MarkSafe.svg';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';
import { ReactComponent as Close } from '../../asset/icons/Close.svg';
import BloodFilter from './BloodFilter';

function BloodDonor() {
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [filterVisible, setFilterVisible] = useState(false);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const toggleFilterVisibility = () => {
        if (filterVisible) {
            setSelectedFilter('All');
        }
        setFilterVisible(!filterVisible);
    };

    const filterButtonStyle = {
        backgroundColor: '#467AFF',
        color: 'white',
    };

    const filterOptions = ['Alumni', 'Student', 'Entrepreneurs', 'Faculty'];

    return (
        <Tab.Container defaultActiveKey='SafeEvent'>
            <Row className='px-2 align-items-center'>
                <Col className='d-flex justify-content-start'>
                    <Nav variant='pills'>
                        <Nav.Item className='ml-auto'>
                            <Nav.Link
                                type='button'
                                className='px-4 py-2'
                                style={filterButtonStyle}
                                onClick={toggleFilterVisibility}
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    {filterVisible ? <Close /> : <Filter />}
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>{' '}
                </Col>
                {filterVisible && (
                    <Col className='d-flex justify-content-start'>
                        {filterOptions.map((option) => (
                            <Button
                                key={option}
                                variant='outline-primary'
                                className='mx-1'
                                onClick={() => handleFilterChange(option)}
                                active={selectedFilter === option}
                            >
                                {option}
                            </Button>
                        ))}
                    </Col>
                )}
            </Row>

            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='SafeEvent' title='SafeEvent'>
                    <BloodDonorTable selectedFilter={selectedFilter} />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default BloodDonor;
