import React from 'react';
import { Col } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

function UserGroup() {
    const { response, loading } = useAxiosGetMultiple({
        gcCount: endpoints.instituteMember.gcMemberCount,
        cityChapterCount: endpoints.instituteMember.cityChapterCount,
        studentCouncilCount: endpoints.instituteMember.studentCouncilCount,
        classRepCount: endpoints.instituteMember.classRepCount,
        batchRepCount: endpoints.instituteMember.batchRepCount,
        batch: endpoints.instituteMember.batch,
        course: endpoints.course.get,
    });

    const {
        gcCount,
        cityChapterCount,
        studentCouncilCount,
        classRepCount,
        batchRepCount,
        batch,
        course,
    } = response;

    if (loading) {
        return <Loader />;
    }
    return (
        <div>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>
                User Groups
            </span>
            <br />
            <span style={{ fontSize: '12px', fontWeight: '500' }}>
                Based on Institution
            </span>
            <Col
                className='px-2 py-3'
                style={{
                    background: '#EFEEFE',
                    borderRadius: '10px',
                }}
            >
                <Card
                    name='Governing Council'
                    count={gcCount.governingCouncilCount}
                    based='Based on Institution'
                />
                <Card
                    name='City Chapter'
                    count={cityChapterCount.cityChapterHeadCount}
                    based='Based on Institution'
                />
                <Card
                    name='Student Council'
                    count={studentCouncilCount.studentCouncilCount}
                    based={<span>In Total {course.length} Courses</span>}
                />

                <Card
                    name='Class Representatives'
                    count={classRepCount.classRepCount}
                    based={<span>In Total {batch.batchCount} Batches</span>}
                />
                <Card
                    name='Batch Representatives'
                    count={batchRepCount.batchRepCount}
                    based={<span>In Total {batch.batchCount} Batches</span>}
                />
            </Col>
        </div>
    );
}

export default UserGroup;

const Card = ({ name, count, based }) => (
    <div
        style={{
            background: '#FFFFFF',
            display: 'flex',
            padding: '2%',
            borderRadius: '10px',
            marginBottom: '2%',
        }}
    >
        <div>
            <span style={{ fontSize: '14px', fontWeight: '700' }}>{name}</span>
            <br />
            {based}
        </div>
        <div
            style={{
                marginLeft: 'auto',
                width: '70px',
                background: '#467AFF',
                height: '61px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                color: '#FFFFFF',
                fontSize: '24px',
                fontWeight: '900',
            }}
        >
            {count}
        </div>
    </div>
);
