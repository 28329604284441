import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import StudentPersonalTagForm from './StudentPersonalTagForm';
import { useAuthenticationState } from '../../context/Auth.context';

function StudentPersonalTag() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(`${endpoints.alumniPersonalTag.get}student`);

    const fetchUtilsWithFilteredData = {
        ...fetchUtils,
        response: fetchUtils.response?.filter(
            (item) => item.TYPE === 'STUDENT'
        ),
    };

    const userType = [
        'CLASSREPRESENTATIVE',
        'CLASSREP + STUDENTCOUNCIL',
    ];

    const cellModifier = {
        MAPPING: ({ value }) => {
            // Extract unique tag names using Set
            const uniqueTags = [
                ...new Set(value?.map((tag) => tag?.Alumni_Personal_Tag_Name)),
            ];

            return (
                <div
                    style={{
                        maxWidth: '500px',
                        overflow: 'auto',
                        paddingBottom: '5px',
                    }}
                >
                    {uniqueTags?.map((tagName) => (
                        <div
                            key={tagName}
                            className='p-2 me-2 d-inline-block small'
                            style={{
                                background: 'rgba(95, 179, 246, 0.1)',
                                borderRadius: '10px',
                            }}
                        >
                            {tagName}
                        </div>
                    ))}
                </div>
            );
        },
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniPersonalTag}
                Form={StudentPersonalTagForm}
                {...viewProps.StudentPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel='Add Mapping'
                formSize='md'
                irremovable
                immutable={userType.includes(user.Role)}
                insertable={!userType.includes(user.Role)}
                {...fetchUtilsWithFilteredData}
            />
        </>
    );
}

export default StudentPersonalTag;
