import React, { useState } from 'react';
import {
    Col,
    Nav,
    Row,
    Tab,
    Dropdown,
    DropdownButton,
    ButtonGroup,
    Form,
} from 'react-bootstrap';
import styles from './Batch.css';
import ClassRep from './classRep';
import BloodDonor from './BloodDonor';

function ClassRepBloodDonor({ memberList, bloodDonor }) {
    return (
        <Tab.Container defaultActiveKey='ClassRep'>
            <Row className='px-2'>
                <Col md='10' className='w-100'>
                    <Nav
                        variant='pills'
                        className={`w-100 text-center shadow-sm ${styles.nav}`}
                    >
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='ClassRep'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Class Representatives
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='BloodDonor'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Blood Donors
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3'>
                <Tab.Pane eventKey='ClassRep' title='Class Representative'>
                    {memberList?.length > 0 ? (
                        <ClassRep classRepList={memberList} />
                    ) : (
                        'No Class representatives are present'
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey='BloodDonor' title='BloodDonor'>
                    {bloodDonor?.length > 0 ? (
                        <BloodDonor bloodDonor={bloodDonor} />
                    ) : (
                        'No Blood donors are present'
                    )}
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default ClassRepBloodDonor;
