import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet, useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';

function BloodDonor() {
    const { response, loading, reFetch } = useAxiosGetMultiple({
        fetchUtils: endpoints.statistics.bloodDonor,
    });

    const fetchUtils = {
        response: response.fetchUtils.alumniDetails,
        loading: loading,
        reFetch: reFetch,
    };

    return (
        <>
            <CRUDTable
                {...viewProps.BloodDonor}
                // cellModifier={cellModifier}
                irremovable
                immutable
                insertable={false}
                {...fetchUtils}
            />
        </>
    );
}

export default BloodDonor;
