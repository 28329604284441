import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import Avatar from '../../asset/img/Avatar.png';

function MarkAsSafeTable({ selectedFilter }) {
    const { user } = useAuthenticationState();
    const getApi = endpoints.safetyResponse.get;
    const fetchUtils = useAxiosGet(getApi);

    const filteredResponse = fetchUtils.response?.filter((item) => {
        switch (selectedFilter) {
            case 'Unsafe':
                return item.Alumni_MarkAsSafe_Response === 'AT RISK';
            case 'NoResponse':
                return item.Alumni_MarkAsSafe_Response === 'NO RESPONSE';
            case 'Safe':
                return item.Alumni_MarkAsSafe_Response === 'SAFE';
            default:
                return true;
        }
    });

    const fetchUtilsWithFilteredData = {
        ...fetchUtils,
        response: filteredResponse,
    };

    const cellModifier = {
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
        ALUMNI_Name: ({ row }) => {
            if (row.original) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <img
                            src={row.original.ALUMNI_IMAGE_URL || Avatar}
                            alt='Avatar'
                            className='rounded-circle shadow-sm'
                            style={{
                                border: '3px solid white',
                                width: '20px',
                                height: '20px',
                            }}
                            width='30'
                        />

                        <span
                            style={{
                                fontSize: '14px',
                                paddingLeft: '2%',
                            }}
                        >
                            {row.original.ALUMNI_Name}
                        </span>
                    </div>
                );
            } else {
                return row.original;
            }
        },
        Alumni_MarkAsSafe_Response: ({ row }) => {
            const status = {
                'AT RISK': { tag: 'Approved', variant: 'red' },
                SAFE: { tag: 'Rejected', variant: 'green' },
                'NO RESPONSE': { tag: 'Not Responded', variant: 'red' },
            };

            const response = row.original.Alumni_MarkAsSafe_Response;
            const color = status[response]?.variant || 'red';

            return <span style={{ color }}>{response}</span>;
        },
    };

    return (
        <>
            <Table
                {...viewProps.MarkAsSafeDash}
                cellModifier={cellModifier}
                formSize='md'
                {...fetchUtilsWithFilteredData}
            />
        </>
    );
}

export default MarkAsSafeTable;
