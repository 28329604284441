const endpoints = Object.freeze({
    authentication: {
        login: '/web/auth/v1.5/login',
        activate: '/web/auth/activate',
        token: '/web/auth/token ',
        changePassword: '/web/auth/changePassword',
        forgotPassword: '/web/auth/admin/confirmForgotPassword',
        institutions: '/web/institutions/withouttoken/list',
    },
    notification: {
        admin: '/web/notificationsforadmin/list',
        delegate: '/web/notificationsforadmin/list/delegate/',
        read: '/web/notificationsforadmin/read',
        cch: 'web/notificationsforadmin/list/cch/',
        cchdelegate: '/web/notificationsforadmin/list/ccdelegate/',
    },
    alumniDetail: {
        get: '/web/profile/',
        getAlumniOnly: '/web/profile/alumni',
        getStudentOnly: '/web/profile/student',
        getFacultyOnly: '/web/profile/faculty',
        getInstituteMemberOnly: '/web/profile/institutemember',
        getBatchWise: '/web/batchRep/alumniList',
        getBatchAlumni: 'web/profile/alumnilist/',
        add: '/web/profile/add',
        // add:'/web/auth/v1.5/institutemember/add',
        update: '/web/profile/update',
        delete: '/web/profile/delete',
        resetPassword: '/web/profile/resetPassword',
        // bulkUpload: '/web/bulkupload/upload/alumnidetails',
        // bulkUploadTemplate: '/web/bulkupload/template/alumnidetails',
        bulkUpload: '/web/bulkupload',
        bulkUploadConfirm: '/web/bulkupload/dependency/addNew',
        bulkUploadTemplate: '/web/bulkupload/template/alumnidetails',
        getStudentStudentCouncil: '/web/studentDetails/',
    },
    batchRep: {
        batchRepUnsafeMarkSafe: '/web/batchRepOverview/unsafeMarkAsSafeEvents',
        batchRepTotalHelpRequests: '/web/batchRepOverview/totalHelpRequests',
        batchRepTotalNoResponses: '/web/batchRepOverview/totalNoResponses',
        batchRepTotalAlumniCount: '/web/batchRepOverview/totalAlumniCount',
        batchRepInactiveAlumniCount:
            '/web/batchRepOverview/inActiveAlumniCount',
        batchRepTotalJobRequests: '/web/batchRepOverview/totalJobRequests',
    },
    instituteMemberDetail: {
        getInstituteMemberOnly: '/web/profile/institutemember',
        add: '/web/auth/v1.5/institutemember/add',
        update: '/web/profile/update',
        delete: '/web/profile/delete',
    },
    alumniPersonalTag: {
        get: '/web/personaltag/mapping/',
        add: '/web/personaltag/mapping/add',
        update: '/web/personaltag/mapping/update',
        delete: '/web/personaltag/mapping/delete',
    },
    cityChapters: {
        get: '/web/chaptertag/mapping/cityChapterList',
        add: '/web/chaptertag/add',
        update: '/web/chaptertag/update',
        delete: '/web/chaptertag/delete',
    },
    cityChapterAlumniMap: {
        get: '/web/chaptertag/mapping/cityChapterList',
        getAlumniOnly: '/web/chaptertag/mapping/new/alumnilist',
        add: '/web/chaptertagcrudmapping/add',
        delete: '/web/chaptertagcrudmapping/delete',
        bulkUpload: '/web/cc-map-bulk-upload',
        bulkUploadTemplate: '/web/bulkupload/template/cityChapterAlumniMap',
    },
    statistics: {
        basics: '/stats/dashboardcomplete',
        ecMembers: '/stats/executivememberslist',
        classRepList: '/web/dashboardstatistics/classRep/listBasedOnCourse',
        studentCouncilMarkAsSafe:
            '/web/dashboardstatistics/studentCouncil/markasSafe',
        studentCouncilEvents: '/web/dashboardstatistics/studentCouncil/events',
        studentCouncilAnnouncements:
            '/web/dashboardstatistics/studentCouncil/announcements',
        studentCouncilHelpRequests:
            '/web/dashboardstatistics/batchRepHelpRequests',
        studentCouncilJobRequests:
            '/web/dashboardstatistics/batchRepJobRequests',
        cityChapterInclusion: '/stats/locationandcittychaptermapping',
        alumniJobIndustry: '/stats/count_alumniDetailsByIndustry',
        alumniJobOrg: '/stats/count_alumniDetailsByOrg',
        entreByIndustry: '/stats/count_entreAlumniDetailsByIndustry',
        alumniPerYear: '/stats/count_aluminiDetailByGraduationYear',
        studentCouncilOverView:
            '/dashboardOverview/studentCouncilDashboard/Overview',
        enterList: '/entrepreneursList/activeEntrepreneursList',

        // test
        batchdashboard: '/dashboardOverview/batchRepDashboard/Overview',
        entreprener: '/web/citychapter/entreprener/list',
        // bloodDonor: '/web/citychapter/blooddonor/list',
        bloodDonor: '/web/dashboardstatistics/blooddonorList',

        inActiveAlumni:
            '/web/citychapter/citychapterDashboardOverview/inActiveAlumniCount/',
        cchAlumniCount: '/web/citychapter/citychapteralumnicount',
        cchTotalHelp:
            '/web/citychapter/citychapterDashboardOverview/totalHelpRequests',
        cchUnsafe:
            '/web/citychapter/citychapterDashboardOverview/unsafeResponse/',
        cchUnrespondSafeEvent:
            '/web/citychapter/citychapterDashboardOverview/notRespondedResponse/',
        cchOverview: '/web/citychapter/citychapterDashboardOverview/',
        cchTotalAlumni:
            '/web/citychapter/citychapterDashboardOverview/totalAlumniCount/',
        classTotalStudent:
            '/dashboardOverview/classRepDashboardOverView/totalStudentsCount',
    },
    studentCouncilOverview: {
        unsafeResponse: '/web/ScOverview/unsafeResponse',
        helpRequestsToApprove: '/web/ScOverview/helpRequestsToApprove',
        jobrequestsToApprove: '/web/ScOverview/jobRequestsToApprove',
        totalStudentCount: '/web/ScOverview/totalStudentsCount',
        inActiveStudentCount: '/web/ScOverview/inActiveStudentsCount',
        batchCount: '/web/ScOverview/batchCount',
    },
    classRepDash: {
        totalStudent:
            '/dashboardOverview/classRepDashboardOverView/totalStudentsCount',
        inactiveStudent:
            '/dashboardOverview/classRepDashboardOverView/inActiveStudentsCount',
        studentEnterpreneur:
            '/dashboardOverview/classRepDashboardOverView/studentEntrepreneurs',
        totalHelpRequest:
            '/dashboardOverview/classRepDashboardOverView/totalHelpRequests',
        unsafeResponse:
            '/dashboardOverview/classRepDashboardOverView/unsafeResponse',
        helpRequest: '/web/dashboardstatistics/classRep/HelpRequests',
        job: '/web/dashboardstatistics/classRep/JobRequests',
    },
    scDashboardOverview: {
        totalStudentCount:
            '/dashboardOverview/classRepDashboardOverView/totalStudentsCount',
        inActiveStudentCount:
            '/dashboardOverview/classRepDashboardOverView/inActiveStudentsCount',
        studentEntrepreneurs:
            '/dashboardOverview/classRepDashboardOverView/studentEntrepreneurs',
        totalHelpRequests:
            '/dashboardOverview/classRepDashboardOverView/totalHelpRequests',
        unsafeResponse:
            '/dashboardOverview/classRepDashboardOverView/unsafeResponse',
        bloodDonors: '/web/citychapter/blooddonor/list',
    },

    eventCalendar: {
        get: '/web/events/',
        delegate: '/web/batchdelegateview/events/',
        studentCouncil: '/web/studentEventList',
        respond: '/web/events/approve',
        add: '/web/events/add',
        update: '/web/events/update',
        delete: '/web/events/delete',
        studentEventsList: '/web/studentEventList/',
    },
    instituteMember: {
        unsafeResponse:
            '/web/institutionmember/instituteMemberDashboardOverview/unsafeResponse',
        totalHelpRequests:
            '/web/institutionmember/instituteMemberDashboardOverview/totalHelpRequests',
        totalJobRequests:
            '/web/institutionmember/instituteMemberDashboardOverview/totalJobreqCount',
        registeredStudents:
            '/web/institutionmember/instituteMemberDashboardOverview/totalStudentCount',
        inactiveStudents:
            '/web/institutionmember/instituteMemberDashboardOverview/totalInactiveAlumniCount',
        totalAlumni:
            '/web/institutionmember/institutememberDashboardOverview/totalAlumniCount',
        inactiveAlumni:
            '/web/institutionmember/instituteMemberDashboardOverview/totalInactiveAlumniCount',
        facultyCount:
            '/web/institutionmember/instituteMemberDashboardOverview/totalFacultyCount',
        inactiveFaculty:
            '/web/institutionmember/instituteMemberDashboardOverview/totalInactiveFacultyCount',
        gcMemberCount:
            '/web/institutionmember/instituteMemberDashboardOverview/governingCouncilCount',
        cityChapterCount:
            '/web/institutionmember/instituteMemberDashboardOverview/cityChapterHeadCount',
        studentCouncilCount:
            '/web/institutionmember/instituteMemberDashboardOverview/studentCouncilCount',
        classRepCount:
            '/web/institutionmember/instituteMemberDashboardOverview/classRepCount',
        batchRepCount:
            '/web/institutionmember/instituteMemberDashboardOverview/batchRepCount',
        batch: '/web/institutionmember/instituteMemberDashboardOverview/batchCount',
    },
    scEventCalendar: {
        get: '/web/events/',
        delegate: '/web/batchdelegateview/events/',
        studentCouncil: '/web/studentEventList',
        respond: '/web/events/approve',
        add: '/web/events/add',
        update: '/web/events/update',
        delete: '/web/studentEventList/delete',
        studentEventsList: '/web/studentEventList/',
    },
    rsvp: {
        get: '/web/events/rsvp/',
    },
    announcements: {
        get: '/web/announcement/',
        respond: '/web/announcement/respond',
        delete: '/web/announcement/delete',
        studentCouncil: '/web/studentannouncement',
    },
    alumniLookingForJob: {
        get: '/web/job/requirements',
        respond: '/web/job/jobrequirementstatus',
        delegate: '/web/batchdelegateview/jobrequirementlist/',
        delete: '/jobs/web/delete',
        jobRequestsForApproval: '/web/dashboardstatistics/batchRep/jobRequests',
        scJobRequestsForApproval:
            '/web/dashboardstatistics/studentCouncil/JobRequests',
        instituteMemberPendingJobRequest:
            '/web/dashboardstatistics/instituteMember/JobRequests',
    },
    postedJobs: {
        get: '/web/job/posted',
        delegate: '/web/batchdelegateview/joblist/',
        respond: '/web/job/joblistingstatus',
        delete: '/jobList/delete',
        studentJobList: '/web/studentJobList',
        cchPendingJobs: '/web/dashboardstatistics/cch/jobRequests',
    },
    markAsSafe: {
        get: '/web/studentmarksafe/',
        add: '/web/marksafe/add',
        update: '/web/marksafe/update',
        delete: '/web/marksafe/delete',
        batchBased: '/web/dashboardstatistics/batchRep/markasSafe',
    },
    adminMarkAsSafe: {
        get: '/web/marksafe/',
        add: '/web/marksafe/add',
        update: '/web/marksafe/update',
        delete: '/web/marksafe/delete',
    },
    scmarkAsSafe: {
        get: '/web/studentmarksafe/',
        add: '/web/studentmarksafe/add',
        delete: '/web/studentmarksafe/delete',
    },
    safetyResponse: {
        get: '/web/marksafe/mapping/',
    },
    helpRequests: {
        get: '/web/requesthelp/',
        delegate: '/web/batchdelegateview/helplist/',
        respond: '/web/requesthelp/respond',
        delete: '/requesthelp/delete',
        studentHelpRequest: '/web/studentHelpRequest/',
        helpRequestsForApproval:
            '/web/dashboardstatistics/batchRepresentative/helpRequests',
        cchHelpRequestsForApproval:
            '/web/dashboardstatistics/cch/helpRequests/',
        scHelpRequestForApproval:
            '/web/dashboardstatistics/studentCouncil/helpRequests',
        instituteRequestForApproval:
            '/web/dashboardstatistics/instituteMember/HelpRequests',
    },
    covidVaccineStatus: {
        get: '/web/vaccine',
    },
    governingCouncil: {
        get: '/web/ecmembers/',
        add: '/web/ecmembers/add',
        update: '/web/ecmembers/update',
        delete: '/web/ecmembers/delete',
        activate: '/web/ecmembers/activate',
    },
    cityChapterHead: {
        get: '/web/chapterhead/',
        add: '/web/chapterhead/add',
        update: '/web/chapterhead/update',
        delete: '/web/chapterhead/delete',
        // activate: '/web/chapterhead/activate',
    },
    personalTags: {
        get: '/web/personaltag/',
        add: '/web/personaltag/add',
        update: '/web/personaltag/update',
        delete: '/web/personaltag/delete',
    },
    topicOfInterest: {
        get: '/web/topicofinterest/enum/',
        add: '/web/topicofinterest/enum/add',
        update: '/web/topicofinterest/enum/update',
        delete: '/web/topicofinterest/enum/delete',
        approve: '/web/topicofinterestapprove/approve',
        reject: '/web/topicofinterestapprove/reject',
    },
    course: {
        get: '/web/courses/v1.5/listbasedoninstitutions',
        add: '/web/courses/v1.5/add',
        update: '/web/courses/update',
        delete: '/web/courses/delete',
    },
    benefitProviders: {
        get: '/web/insuranceprovider/',
        add: '/web/insuranceprovider/add',
        update: '/web/insuranceprovider/update',
        delete: '/web/insuranceprovider/delete',
    },
    healthInsuranceDetails: {
        get: '/web/insurance/health',
        delete: 'healthinsurance/web/delete',
    },
    lifeInsuranceDetails: {
        get: '/web/insurance/life',
        delete: 'lifeinsurance/delete',
    },
    batch: {
        get: '/web/batchdetails/list',
        add: '/web/batchdetails/add',
        update: '/web/batchdetails/update',
        delete: '/web/batchdetails/delete',
        activate: '/web/batchdelegate/activate',
        scBatch: '/web/studentmarksafe/batch',
    },
    batchHeads: {
        get: '/web/batchdelegate/list',
        add: '/web/batchdelegate/add',
        update: '/web/batchdelegate/update',
        delete: '/web/batchdelegate/delete',
    },
    industry: {
        get: '/common/org/list/industry',
    },
    organization: {
        get: '/common/org/list/organization',
    },
    studentCouncil: {
        get: '/web/studentCouncil',
        add: '/web/studentCouncil/add',
        // update: '/web/studentCouncil/update',
        // update: '/web/studentCouncil/update',
        delete: '/web/studentCouncil/delete',
    },
    classRep: {
        get: '/web/classRep/list',
        add: '/web/classRep/add',
        update: '/web/classRep/switchClassRep',
        delete: '/web/classRep/delete',
    },
    classRepDashboard: {
        activeEntrepreneurs: '/entrepreneursList/activeEntrepreneursList',
        overView: 'dashboardOverview/classRepDashboard/Overview',
        announcementsList: '/web/studentannouncement',
    },
    studentLookingForJob: {
        get: '/web/studentjobreq/requirements',
        respond: '/web/job/jobrequirementstatus',
        delegate: '/web/batchdelegateview/jobrequirementlist/',
        delete: '/jobs/web/delete',
    },
    studentAnnouncements: {
        get: '/web/studentannouncement',
        add: '/web/studentannouncement/add/sc',
        update: '/web/studentannouncement/update',
        delete: '/web/studentannouncement/delete',
    },
    alumniBulkUpload: {
        fileUpload: '/web/userBulkUpload/uploadFile',
        validateFile: '/web/userBulkUpload/validateData',
        initialBulkInsert: '/web/userBulkUpload/initialInsert',
        bulkUploadConfirm: '/web/userBulkUpload/dependency/addNew',
    },
    promos: {
        get: '/web/promos/list',
    },
    // admin: {
    //     get: '/web/institutions',
    //     add: '/web/auth/v1.5/admin/add',
    //     update: '/web/institutions/update',
    //     delete: '/web/institutions/delete',
    // },
});

export default endpoints;
